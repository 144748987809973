import React, { createContext, useContext, useState } from "react"
import styled, { ThemeProvider } from "styled-components"
import { whiteTheme, darkGreyTheme } from "../theme"
import media from "../utils/mediaQueries"
import { Location } from "@reach/router"
import Home from "../components/Home"
import { saveTrialPlanDetails } from "../data/pricing"
import useScript from "../utils/hooks/useScript"

const ThemeToggleContext = createContext()

const defaultValues = {
  mode: "light",
  affiliateId: "",
  pId: "",
  showLoader: true,
  pricingPlanResponse: null,
  promoCode: "",
  savePlanAPIInprogress: false,
}

// Consumer function for access
export const useTheme = () => useContext(ThemeToggleContext)

export const MyThemeProvider = ({ children }) => {
  const [themeState, setThemeState] = useState(defaultValues)

  const toggleDarkMode = () => {
    const mode = themeState.mode === "light" ? "dark" : "light"
    setThemeState({
      ...themeState,
      mode: mode,
    })
  }

  const saveTrialPlan = (planName) => {
    if (themeState.savePlanAPIInprogress) return
    setThemeState({
      ...themeState,
      savePlanAPIInprogress: true,
    })
    saveTrialPlanDetails(planName, themeState, setThemeState)
  }

  const getPromoQueryParam = () => {
    if (themeState.promoCode) {
      return `?promo=${themeState.promoCode}`
    }
    return ""
  }

  const theme = {
    ...media,
    ...themeState,
  }

  // useScript("//fast.appcues.com/36511.js", true)
  return (
    <Location>
      {({ location, navigate }) => (
        <ThemeToggleContext.Provider
          value={{
            ...themeState,
            toggleDarkMode,
            setThemeState,
            saveTrialPlan,
            getPromoQueryParam,
          }}
          location={location}
        >
          <ThemeProvider theme={theme}>
            <Home theme={theme} location={location} />
            <Wrapper>{children}</Wrapper>
          </ThemeProvider>
        </ThemeToggleContext.Provider>
      )}
    </Location>
  )
}

const Wrapper = styled.div`
  background-color: ${whiteTheme};
  color: ${darkGreyTheme};
`
